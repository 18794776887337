import React, { useState, useEffect } from "react";
import {
  Tabs,
  Select,
  CopyTextButton,
  Button,
  Drawer,
} from "../../../../../components";
import { useGlobalContext } from "../../../../../context";
import localData from "../../../../../localData";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import TrackingHelp from "../../../tracking-help/TrackingHelp";
import { postAPIwithAuth } from "../../../../../services/webService";
import FormDrawer from "../../../../zone/Drawer/Form/Drawer";
const { pixel } = localData.images;
const { plus } = localData.svgs;

export default function CreateConversionTab({
  setActiveTab,
  callbackFromParent,
}) {
  const [showCreateConversionModal, setShowCreateConversionModal] =
    useState("none");

  const {
    trackingState,
    ensureTokenValid,
    isTokenExpired,
    updateTokens,
    refreshAccessToken,
  } = useGlobalContext();
  const [requestedData, setRequestedData] = useState([]);
  const { editingTagId, isEditingMode } = trackingState;

  useEffect(() => {
    const fetchZoneData = async () => {
      const user = JSON.parse(localStorage.getItem("user"));

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/publisher/zone-all.php?access_token=` +
            user.access_token,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              affiliateid: editingTagId,
            }),
          }
        );

        const result = await response.json();
        setRequestedData(result.result);
        localStorage.setItem("requestedData", JSON.stringify(result.result));
      } catch (error) {
        console.error("Fetch error:", error);
        if (error.name === "TypeError" && error.message === "Failed to fetch") {
          await updateTokens();
          const user = await JSON.parse(localStorage.getItem("user"));
          const response = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/publisher/zone-all.php?access_token=` +
              user.access_token,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                affiliateid: editingTagId,
              }),
            }
          );

          const result = await response.json();
          setRequestedData(result.result);
          localStorage.setItem("requestedData", JSON.stringify(result.result));
        } else {
          console.error("An error occurred:", error);
        }
      }
    };

    fetchZoneData();
  }, [editingTagId, isEditingMode]);

  const createConversioFormTabs = [
    {
      title: "Track an event",
      content: (
        <TrackAnEventContent
          requestedData={requestedData}
          editingTagId={editingTagId}
        />
      ),
      id: "track-an-event-2983jfosfod",
    },
  ];

  return (
    <div className="create-conversion-tab">
      <h2 className="create-conversion-tab-title display-2"></h2>

      <Tabs
        tabsProps={{}}
        tabs={createConversioFormTabs}
        title="Create conversion"
        className="create-conversion-tabs"
      />
      <br />
      <div className="create-conversion-tab-footer"></div>
    </div>
  );
}

const TrackAnEventContent = ({ requestedData, editingTagId }) => {
  const Invocationcode = { code: "test" };
  const {
    conversionOptions: { trackAnEventScriptTag },
    setConversionOptions,
    trackingState,
  } = useGlobalContext();
  const { isEditingMode } = trackingState;
  const [zoneId, setZoneId] = useState(0);
  const [selectedType, setSelectedType] = useState(0);
  const [tagtypes, setTagtypes] = useState([
    {
      id: 1,
      title: "Asynchronous Tag",
      isActive: true,
    },
    {
      id: 2,
      title: "JavaScript Tag",
      isActive: false,
    },
    {
      id: 3,
      title: "Iframe Tag",
      isActive: false,
    },
  ]);
  const [state, setState] = useState({ tagName: "", url: "", contains: "" });
  const [items, setItems] = useState([]);
  const { ensureTokenValid, isTokenExpired, updateTokens, refreshAccessToken } =
    useGlobalContext();
  useEffect(() => {
    if (Array.isArray(requestedData)) {
      if (requestedData.length > 0) {
        const options = requestedData.map((zone) => ({
          id: zone.zoneid,
          title: zone.zonename,
          isActive: false,
        }));
        setItems(options);
      }
    }
    console.log(requestedData);
  }, [requestedData]);

  useEffect(() => {
    if (Number(zoneId) > 0) {
      fetchInvocationData();
    }
  }, [selectedType, zoneId]);

  const fetchInvocationData = async () => {
    console.log('fetching');
    trackAnEventScriptTag.isLoading = true;
    try {
      await ensureTokenValid();
      if (selectedType == 0) {
        setSelectedType(1);
      }
      const invocation = await postAPIwithAuth(
        `publisher/zone-invocation.php`,
        {
          zone_id: zoneId,
        }
      );
      console.log(invocation);
      if (selectedType === 3) {
        setConversionOptions((prevState) => ({
          ...prevState,
          trackAnEventScriptTag: {
            ...prevState.trackAnEventScriptTag,
            isLoading: false,
            value: invocation.iframecode,
          },
        }));
      } else if (selectedType === 2) {
        setConversionOptions((prevState) => ({
          ...prevState,
          trackAnEventScriptTag: {
            ...prevState.trackAnEventScriptTag,
            isLoading: false,
            value: invocation.jscode,
          },
        }));
      } else if (selectedType === 1) {
        setConversionOptions((prevState) => ({
          ...prevState,
          trackAnEventScriptTag: {
            ...prevState.trackAnEventScriptTag,
            isLoading: false,
            value: invocation.asynctag,
          },
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectChange = (updatedItems, type) => {
    if (type === "type") {
      const itemsArray = Array.isArray(updatedItems)
        ? updatedItems
        : [updatedItems];
      const selectedItem = itemsArray.find((item) => item.isActive === true);
      setSelectedType(selectedItem.id);
    } else if (type === "zone") {
      const itemsArray = Array.isArray(updatedItems)
        ? updatedItems
        : [updatedItems];
      const selectedItem = itemsArray.find((item) => item.isActive === true);
      setZoneId(selectedItem.id);
    }
    console.log(updatedItems, type);
    const itemsArray = Array.isArray(updatedItems)
      ? updatedItems
      : [updatedItems];
    const selectedItem = itemsArray.find((item) => item.isActive === true);
    console.log(selectedItem);
    if (!selectedItem) {
      console.error("No item is selected in items:", itemsArray);
      return;
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
  };

  const [isTagTypesDisplayed, setIsTagTypesDisplayed] = useState(false);

  useEffect(() => {
    const isDisplayed = items.find((item) => item.isActive) ? true : false;
    setIsTagTypesDisplayed(!isDisplayed);
  }, [items]);

  return (
    <div className="step-1">
      <br />
      <div className="form-legend">
        <form action="" onSubmit={handleOnSubmit}>
          <Select
            items={items}
            setItems={setItems}
            placeholder="Select Zone"
            isLoading={false}
            variant="outlined"
            color="light"
            className="organization-select"
            callback={(items) => handleSelectChange(items, "zone")}
          />

          {isTagTypesDisplayed ? (
            ""
          ) : (
            <Select
              items={tagtypes}
              setItems={setTagtypes}
              placeholder="Select Invocation Type"
              isLoading={false}
              variant="outlined"
              color="light"
              className="organization-select"
              callback={(tagtypes) => handleSelectChange(tagtypes, "type")}
            />
          )}
        </form>
      </div>
      <p className="text-style-1">
        Copy and paste this code on your website where you want the ad to
        appear.
      </p>
      <div className="code">
        <div className="row">
          <div className="label">
            <img src={pixel} alt="" />
            code
          </div>
          <CopyTextButton
            text={trackAnEventScriptTag.value}
            disabled={!trackAnEventScriptTag.value}
            size="medium"
          />
        </div>

        <div className="script-wrapper" style={{ fontSize: "12px" }}>
          {trackAnEventScriptTag.isLoading ? (
            "Loading..."
          ) : trackAnEventScriptTag.value ? (
            <SyntaxHighlighter
              language="html"
              style={coy}
              showLineNumbers={true}
            >
              {trackAnEventScriptTag.value}
            </SyntaxHighlighter>
          ) : (
            "Select a zone from the dropdown above to generate the invocation code"
          )}
        </div>
      </div>
      <br />
      <br />
      <AddNewZone website={editingTagId} />
    </div>
  );
};

const AddNewZone = ({ website }) => {
  const [showzoneWizardDrawer1, setShowzoneWizardDrawer1] = useState("none");
  const initialFormData = {
    zonename: "",
    zonetype: "1",
    zonesize: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [openFormDrawer, setOpenFormDrawer] = useState(false);
  const [zoneData, setZoneData] = useState([]);

  const closeDrawer = () => {
    setOpenFormDrawer(false);
    fetchCampaignData();
  };

  const fetchCampaignData = async () => {
    try {
      const responseData = await postAPIwithAuth(`publisher/zone-all.php`, {
        affiliateid: website,
      });
      setZoneData(responseData.result);
      console.log(responseData);
    } catch (error) {
      console.log(error);
      //LogoutService(navigate);
    }
  };
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="campaigns-wizard campaigns-wizard-create"
    >
      <Drawer
        display={showzoneWizardDrawer1}
        setDisplay={setShowzoneWizardDrawer1}
        toggler={
          <Button
            name="Add new Zone"
            variant="contained"
            startIcon={plus}
            className="add-new-zone-btn"
            color="success"
            size="sm"
            style={{ marginBottom: "10px" }}
            onClick={(e) => {
              setFormData(initialFormData);
              setOpenFormDrawer(true);
            }}
          />
        }
        className=" drawer drawer-right drawer-fullscreen add-new-zone-drawer"
      >
        <FormDrawer
          isOpen={openFormDrawer}
          onClose={closeDrawer}
          website={website}
          postFormData={formData}
        />
      </Drawer>
    </div>
  );
};
