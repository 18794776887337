import React, { useState } from "react";
import {
    Navbar,
    TableSkeleton,
    Button
} from "../../components";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../context";
import localData from "../../localData";
import TrackingDrawer from "../tracking/tracking-drawer/TrackingDrawer";
import useFormatter from "../../hooks/useFormatter";
import ConfirmationDialog from "../../components/confirmationDialog";



const {
    textPreloader,
    trashV2
} = localData.svgs;

export default function Payouts() {
    const { formatDate, formatCurrency, capitalizeFirstLetter } = useFormatter();

    const { fade } = useGlobalContext().animations;
    const {
        withdrawals,
        deletePublisherWithdrawal,
        successAlert
    } = useGlobalContext();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [clickedId, setClickedId] = useState(null);
    const handleConfirm = async () => {
        await deletePublisherWithdrawal(clickedId)
        handleCloseDialog();
        handleCloseDialog()
    }
    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setClickedId(null);
    };


    return (
        <motion.main {...fade} className="main tracking-page">
            <Navbar title="Withdrawals" />
            <br />

            <TrackingDrawer />

            {withdrawals.isLoading ? (
                <TableSkeleton message="Loading..." icon={textPreloader} />
            ) : (
                <>
                    <div className="scroll shadow">
                        <table className="conversion-table">
                            <thead>
                                <tr>
                                    <th className="text-start">Amount</th>
                                    <th>Status</th>
                                    <th>Date created</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!Array.isArray(withdrawals.data) || withdrawals.data.length === 0 ? (
                                    <tr style={{ pointerEvents: "none" }}>
                                        <td colSpan="10">
                                            <div className="no-data">No Data To Show!</div>
                                        </td>
                                    </tr>
                                ) : (
                                    withdrawals.data.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <tr key={index}>
                                                <td className="">
                                                    {formatCurrency(item.amount)}
                                                </td>
                                                <td className="text-center"><span className={`status ${item.payment_status}`}>{capitalizeFirstLetter(item.payment_status)}</span></td>
                                                <td className="text-center">
                                                    {formatDate(new Date(item.created_at))}
                                                </td>
                                                <td className="text-center">
                                                    {item.payment_status === 'pending' ? (
                                                        <Button
                                                        variant="circle"
                                                        icon={trashV2}
                                                        color="danger"
                                                        size="sm"
                                                        onClick={() => {
                                                            setIsDialogOpen(true);
                                                            setClickedId(item.id);
                                                        }}
                                                    />
                                                    ) : ''}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))
                                )}
                            </tbody>
                        </table>
                        <ConfirmationDialog
                            isOpen={isDialogOpen}
                            onClose={handleCloseDialog}
                            onConfirm={handleConfirm}
                            message="Are you sure you want to proceed?"
                        />
                    </div>
                    <br />
                    <br />
                    <br />
                </>
            )}
        </motion.main>
    );
}
