import React, { useEffect, useState } from "react";
import localData from "../../../../localData";
import { useGlobalContext } from "../../../../context";
import { CopyTextButton } from "../../../../components";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";

const { pixel } = localData.images;

const InvocationDrawer = ({ isOpen, onClose, zoneId, zone }) => {
  const [invocationCode, setInvocationCode] = useState(null);
  const [selectedCodeType, setSelectedCodeType] = useState("asynctag");

  const invocationOptions = [
    { value: "asynctag", label: "Asynchronous Tag" },
    { value: "jscode", label: "JavaScript Tag" },
    { value: "iframecode", label: "Iframe Tag" },
  ];

  useEffect(() => {
    if (isOpen && zone?.invocationCode) {
      setInvocationCode(zone.invocationCode[selectedCodeType]);
    }
  }, [isOpen, zone, selectedCodeType]);

  if (!isOpen) return null;

  const handleCodeTypeChange = (e) => {
    const selectedType = e.target.value;
    setSelectedCodeType(selectedType);
    setInvocationCode(zone.invocationCode[selectedType]);
  };

  return (
    <div>
      <h2 className="display-2">Invocation Code</h2>
      <p className="text-style-1">
        Set up invocation code with Blockchain-Ads to have ads display on your
        website.
      </p>

      <div className="step-1">
        <p className="text-style-1">
          Copy and paste this code on your website where you want the ad to
          appear.
        </p>

        <div>
          <label htmlFor="invocation-select" className="label">
            Select Invocation Type:
          </label>
          <select
            id="invocation-select"
            value={selectedCodeType}
            onChange={handleCodeTypeChange}
          >
            {invocationOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className="code">
          <div className="row">
            <div className="label">
              <img src={pixel} alt="" />
              Code
            </div>
            <CopyTextButton
              text={invocationCode}
              disabled={!invocationCode}
              size="medium"
            />
          </div>

          <div className="script-wrapper" style={{ fontSize: "12px" }}>
            {invocationCode && (
              <SyntaxHighlighter
                language="html"
                style={coy}
                showLineNumbers={true}
              >
                {invocationCode}
              </SyntaxHighlighter>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvocationDrawer;
