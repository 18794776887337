import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Button, Drawer, TableSkeleton } from "../../components";

import { motion } from "framer-motion";
import { useGlobalContext } from "../../context";
import localData from "../../localData";
import useFormatter from "../../hooks/useFormatter";
import { postAPIwithAuth } from "../../services/webService";

import ConfirmationDialog from "../../components/confirmationDialog";
import InvocationDrawer from "./Drawer/Invocation/Drawer";
import FormDrawer from "./Drawer/Form/Drawer";
const {
  question,
  penV2,
  textPreloader,
  CodeType,
  copy,
  trashV2,
  plus,
  codeSolid,
} = localData.svgs;
const { avatar, gtm, nextjs, html, nuxt, wordpress, pixel } = localData.images;

export default function Zone({ setDisplay = () => {}, website }) {
  const { successAlert } = useGlobalContext();
  const location = useLocation();
  const [zoneData, setZoneData] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [clickedId, setClickedId] = useState(null);
  const [currentZone, setCurrentZone] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openFormDrawer, setOpenFormDrawer] = useState(false);
  // const { website } = location.state || {};
  // console.log(website);
  const { fade } = useGlobalContext().animations;
  const {
    showCampaingWizardDrawer,
    setShowCampaignWizardDrawer,
    getAdvertiserPixelStatsRequest,
    conversionOptions,
    setConversionOptions,
    requestedData: {
      userProfileData: { currentOrganization },
      campaignData: { currentOrganizationCampaigns },
    },
    isLoading: { campaignDataIsLoading },
    trackingState,
    DeleteConversionPixelRequest,
    ensureTokenValid,
    isTokenExpired,
    updateTokens,
    refreshAccessToken,
  } = useGlobalContext();
  const [showzoneWizardDrawer, setShowzoneWizardDrawer] = useState("none");
  const [showzoneWizardDrawer1, setShowzoneWizardDrawer1] = useState("none");
  const [isStatLoading, setIsStatLoading] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const initialFormData = {
    zonename: "",
    zonetype: "1",
    zonesize: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };
  const element = document.querySelector(".openinvoc");
  const zoneelement = document.querySelector("#edit-zone-btn");
  const openDrawer = (item) => {
    // setIsDrawerOpen(true);
    // element.click();
    // setShowCampaignWizardDrawer('block')

    // setFormData(item);
    setCurrentZone(item);
    setFormData(item);
    // setOpenFormDrawer(true);
    setShowInvocationDrawer("block");
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setOpenFormDrawer(false);
    fetchCampaignData();
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setClickedId(null);
    setCurrentZone(null);
  };

  const handleConfirm = async () => {
    try {
      await ensureTokenValid();
      const responseData = await postAPIwithAuth(`publisher/zone-delete.php`, {
        zoneid: clickedId,
      });
      successAlert("Zone deleted successfully!");
      setZoneData(zoneData.filter((item) => item.zoneid !== clickedId));
      handleCloseDialog();
    } catch (error) {
      console.log(error);
      //LogoutService(navigate);
    }
  };

  const handleDelete = (id) => {
    console.log(id);
    setIsDialogOpen(true);
    setClickedId(id);
  };

  const {
    setDisplayInstallPixel,
    setDefaultTab,
    setIsEditingMode,
    setEditingTagId,
    setState,
  } = trackingState;
  const { formatDateToYYYYMMDD } = useFormatter();

  const setAffiliateid = (website) => {
    setSelectedCampaign(website);
    setEditingTagId(website.affiliateid);
    // localStorage.removeItem("requestedData");
  };

  const fetchCampaignData = async () => {
    try {
      const responseData = await postAPIwithAuth(`publisher/zone-all.php`, {
        affiliateid: website,
      });
      for (let zone of responseData.result) {
        const invocation = await postAPIwithAuth(`publisher/zone-invocation.php`, {
          zone_id: zone.zoneid,
        });
        zone.invocationCode = invocation;
      }
      setZoneData(responseData.result);
    } catch (error) {
      console.log(error);
      //LogoutService(navigate);
    }
  };

  useEffect(() => {
    document.title = "Zone";
    const metaDescription = document.querySelector('meta[name="description"]');
    metaDescription.content =
      "Monitor your progress and stay updated with real-time data on your activities.";
    fetchCampaignData();
  }, [website]);

  const handleEditClick = (item) => {
    setFormData(item);
    setOpenFormDrawer(true);
    // zoneelement.click();
    setShowzoneWizardDrawer("block");
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    setSelectedCampaign(null);
  };

  const handleEditSave = (updatedCampaign) => {
    // Update campaign data here
    handleEditModalClose();
  };
  const handleRefresh = () => {
    fetchCampaignData(); // Call the same function to refresh data
  };

  const [showInvocationDrawer, setShowInvocationDrawer] = useState("none");
  return (
    <td className="zone-row" colSpan={5}>
      <br />
      {campaignDataIsLoading ? (
        <TableSkeleton message="Loading..." icon={textPreloader} />
      ) : (
        <>
          <div className="scroll shadow">
            <table className="conversion-table">
              <thead>
                <tr>
                  <th className="text-start">#</th>
                  <th className="text-start">Zone Name</th>
                  <th>Type</th>
                  <th>Size</th>
                  <th>Date created</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {!Array.isArray(zoneData) || zoneData.length === 0 ? (
                  <tr style={{ pointerEvents: "none" }}>
                    <td colSpan="10">
                      <div className="no-data">No Data To Show!</div>
                    </td>
                  </tr>
                ) : (
                  zoneData.map((item, index) => (
                    <tr key={index}>
                      <td className="text-start">{index + 1}</td>
                      <td className="text-start">
                        {item.zonename || "unknown"}
                      </td>
                      <td className="text-start text-center">
                        {item.delivery == 10 ? (
                          <p>Video Zone</p>
                        ) : (
                          <p>Image Zone</p>
                        )}
                      </td>
                      <td className="text-center">
                        {item.width || "unknown"}X{item.height || "unknown"}
                      </td>
                      <td className="text-center">{item.updated || 0}</td>
                      <td
                        style={{ width: 0 }}
                        className="text-center table-td-actions"
                      >
                        <div className="btn-group">
                          <Button
                            variant="circle"
                            icon={codeSolid}
                            color="dark"
                            size="sm"
                            onClick={() => {
                              setClickedId(item.zoneid);
                              openDrawer(item);
                            }}
                            disabled={isStatLoading}
                          />
                          <Button
                            variant="circle"
                            icon={penV2}
                            color="warning"
                            size="sm"
                            onClick={() => {
                              handleEditClick(item);
                            }}
                          />
                          <Button
                            variant="circle"
                            icon={trashV2}
                            color="danger"
                            size="sm"
                            onClick={() => handleDelete(item.zoneid)}
                            disabled={isStatLoading}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirm}
        message="Are you sure you want to proceed?"
      />
      <Drawer
        display={showInvocationDrawer}
        setDisplay={setShowInvocationDrawer}
        toggler={
          <div className="iubenda_policy">
            <Button
              variant="circle"
              icon={codeSolid}
              color="dark"
              size="sm"
              disabled={isStatLoading}
              className="openinvoc hide"
            />
          </div>
        }
        className=" drawer-right tracking-drawer"
      >
        <InvocationDrawer
          isOpen={true}
          onClose={closeDrawer}
          zoneId={clickedId}
          zone={currentZone}
        />
      </Drawer>
      <div className="campaigns-wizard campaigns-wizard-create">
        <Drawer
          display={showzoneWizardDrawer}
          setDisplay={setShowzoneWizardDrawer}
          toggler={
            <Button
              name="Add new Zone"
              variant="contained"
              startIcon={plus}
              color="success"
              style={{ marginBottom: "10px", display: "none" }}
              id="edit-zone-btn"
            />
          }
          className=" drawer drawer-right drawer-fullscreen"
        >
          <FormDrawer
            isOpen={openFormDrawer}
            onClose={closeDrawer}
            website={website}
            postFormData={formData}
            className="edit-mode"
          />
        </Drawer>
        <br />
        <Drawer
          display={showzoneWizardDrawer1}
          setDisplay={setShowzoneWizardDrawer1}
          toggler={
            <Button
              name="Add new Zone"
              variant="contained"
              startIcon={plus}
              className="add-new-zone-btn"
              color="success"
              size="sm"
              style={{ marginBottom: "10px" }}
              onClick={() => {
                setFormData(initialFormData);
                setOpenFormDrawer(true);
              }}
            />
          }
          className=" drawer drawer-right drawer-fullscreen add-new-zone-drawer"
        >
          <FormDrawer
            isOpen={openFormDrawer}
            onClose={closeDrawer}
            website={website}
            postFormData={formData}
            className="create-mode"
          />
        </Drawer>
      </div>

      <button
        className="refresh"
        onClick={handleRefresh}
        style={{ display: "none" }}
      >
        Refresh Data
      </button>
    </td>
  );
}
